import {call, put} from "typed-redux-saga";
import {Api} from "modules/utils";
import * as actions from "modules/actions";

export const fetchChecksumsJSONSaga = function* () {
	try {
		const response = yield* call(Api.JSON.checksums);
		yield* put(actions.fetchChecksumsJSONSuccess(response));
	} catch (e) {
		const err = e as Error;
		yield* put(actions.fetchChecksumsJSONFailed(err));
	}
};

// export const fetchConstructorsJSONSaga = function* () {
// 	try {
// 		const response = yield* call(Api.JSON.constructors);
// 		ApiError.CHECK(response);
// 		yield* put(actions.fetchConstructorsJSONSuccess(response));
// 	} catch (e) {
// 		yield* put(actions.fetchConstructorsJSONFailed(e));
// 	}
// };

export const fetchEventsJSONSaga = function* () {
	try {
		const response = yield* call(Api.JSON.events);

		yield* put(actions.fetchEventsJSONSuccess(response));
	} catch (e) {
		const err = e as Error;
		yield* put(actions.fetchEventsJSONFailed(err));
	}
};

export const fetchRidersJSONSaga = function* () {
	try {
		const response = yield* call(Api.JSON.riders);
		yield* put(actions.fetchRidersJSONSuccess(response));
	} catch (e) {
		const err = e as Error;
		yield* put(actions.globalError(err));
	}
};
export const fetchHistoricalStatsSaga = function* () {
	try {
		const response = yield* call(Api.JSON.historical_stats);
		yield* put(actions.fetchHistoricalSuccess(response));
	} catch (e) {
		const err = e as Error;
		yield* put(actions.globalError(err));
	}
};

export const fetchFaqSaga = function* () {
	try {
		const response = yield* call(Api.JSON.faq);
		yield* put(actions.fetchFaqSuccess(response));
	} catch (e) {
		const err = e as Error;
		yield* put(actions.fetchFaqFailed(err));
	}
};

export const fetchHelpPagesSaga = function* () {
	try {
		const response = yield* call(Api.JSON.help_pages);
		yield* put(actions.fetchHelpPagesSuccess(response));
	} catch (e) {
		const err = e as Error;
		yield* put(actions.fetchHelpPagesFailed(err));
	}
};

export const fetchCountriesSaga = function* () {
	try {
		const response = yield* call(Api.JSON.countries);
		yield* put(actions.fetchCountriesSuccess(response));
	} catch (e) {
		const err = e as Error;
		yield* put(actions.fetchCountriesFailed(err));
	}
};
