import React, {Fragment, useCallback} from "react";
import styled from "styled-components";
import {FANTASY_LINK} from "modules/utils";
import image_365 from "assets/img/crosspromo/MGP_2024_Fantasy_Launch_LDB_365x100.gif";
import image_728 from "assets/img/crosspromo/MGP_2024_Fantasy_Launch_LDB_728x100.gif";
import image_980 from "assets/img/crosspromo/MGP_2024_Fantasy_Launch_LDB_980x100.gif";
import image_1240 from "assets/img/crosspromo/MGP_2024_Fantasy_Launch_LDB_1240x100.gif";
import image_1472 from "assets/img/crosspromo/MGP_2024_Fantasy_Launch_LDB_1472x100.gif";

import {isLoggedIn} from "modules/selectors";
import {Exist} from "components/Exist";
import {useDispatch, useSelector} from "react-redux";
import cross from "assets/img/cross.svg";
import {hideCrossPromotionModal} from "modules/actions";
import {isNeedToShowCrossPromotion} from "modules/selectors/modals";
import {PersistStorage} from "modules/utils/PersistStorage";

const CrossPromotion = styled.div`
	position: fixed;
	bottom: 0;
	z-index: 13;
	margin: 0 auto 20px;
	width: 100%;
	text-align: center;
	a {
		max-width: 100%;
		img {
			max-width: 90%;
			@media screen and (max-width: 450px) {
				max-width: 75%;
			}
		}
	}
`;
const CloseBtn = styled.button`
	background: url(${cross}) center transparent no-repeat;
	width: 40px;
	height: 40px;
	border: none;
	outline: none;
	position: absolute;
	top: 0;
	cursor: pointer;
`;

const LinkImage = () => (
	<a target="_blank" rel="noopener noreferrer" href={FANTASY_LINK}>
		<picture>
			<source srcSet={image_365} media="(max-width: 750px)" />
			<source srcSet={image_728} media="(max-width: 1000px)" />
			<source srcSet={image_980} media="(max-width: 1260px)" />
			<source srcSet={image_1240} media="(max-width: 1492px)" />

			<img src={image_1472} alt="Predictor" />
		</picture>
	</a>
);
export const HOCCrossPromotion: React.FC = ({children}) => {
	const isLogged = useSelector(isLoggedIn);
	const dispatch = useDispatch();
	const isNeedToShow = useSelector(isNeedToShowCrossPromotion) && isLogged;

	const hide = useCallback(() => {
		dispatch(hideCrossPromotionModal());
		PersistStorage.SET("CrossPromotionHide", "true");
	}, [dispatch]);

	return (
		<Fragment>
			<Exist when={isNeedToShow}>
				<CrossPromotion>
					<LinkImage />
					<CloseBtn onClick={hide} />
				</CrossPromotion>
			</Exist>

			{children}
		</Fragment>
	);
};
