import {createAction} from "redux-act";
import {
	IChecksum,
	ICountry,
	IEvent,
	IFaqItem,
	IHelpPages,
	IHistoricalStats,
	IRider,
} from "modules/types";

export const fetchChecksumsJSON = createAction();
export const fetchChecksumsJSONSuccess = createAction<IChecksum>();
export const fetchChecksumsJSONFailed = createAction<Error>();

// export const fetchConstructorsJSON = createAction();
// export const fetchConstructorsJSONSuccess = createAction<IConstructor[]>();
// export const fetchConstructorsJSONFailed = createAction<Error>();

export const fetchEventsJSON = createAction();
export const fetchEventsJSONSuccess = createAction<IEvent[]>();
export const fetchEventsJSONFailed = createAction<Error>();
//
export const fetchRidersJSON = createAction();
export const fetchRidersJSONSuccess = createAction<IRider[]>();
export const fetchRidersJSONFailed = createAction<Error>();

export const fetchHistoricalStats = createAction();
export const fetchHistoricalSuccess = createAction<IHistoricalStats[]>();

// export const fetchSquadsJSON = createAction();
// export const fetchSquadsJSONSuccess = createAction<ISquad[]>();
// export const fetchSquadsJSONFailed = createAction<Error>();

export const fetchFaq = createAction();
export const fetchFaqSuccess = createAction<IFaqItem[]>();
export const fetchFaqFailed = createAction<Error>();

export const fetchHelpPages = createAction();
export const fetchHelpPagesSuccess = createAction<IHelpPages>();
export const fetchHelpPagesFailed = createAction<Error>();

export const fetchCountries = createAction();
export const fetchCountriesSuccess = createAction<ICountry[]>();
export const fetchCountriesFailed = createAction<Error>();

export const closeInfoBlock = createAction();

export const subscribeToLiveScores = createAction();
export const unsubscribeFromLiveScores = createAction();
