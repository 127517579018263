import {
	IQuestionPredictionPayload,
	IPredictionSavePayload,
	ISagaAction,
	ILeaderboardRequest,
	ILeaderboardPayload,
} from "modules/types";
import {call, put, select, take} from "typed-redux-saga";
import {Api, ApiError} from "modules/utils";
import {
	globalError,
	fetchUserPredictionSuccess,
	postPredictorPredictionSuccess,
	fetchGameBarSuccess,
	fetchLeaderBoardSuccess,
	getMoreLeaderboard,
	fetchLeaderBoard,
	showCrossPromotionModal,
} from "modules/actions";
import {getLeaderBoard} from "modules/selectors";

export const fetchUserPredictionSaga = function* ({
	payload,
}: ISagaAction<IQuestionPredictionPayload>) {
	try {
		const response = yield* call(Api.Predictor.show_my, payload);
		ApiError.CHECK(response);

		if (response.result) {
			yield* put(fetchUserPredictionSuccess(response.result));
		}
	} catch (e) {
		const err = e as Error;
		yield* put(globalError(err));
	}
};

export const postPredictorPredictionSaga = function* ({
	payload,
}: ISagaAction<IPredictionSavePayload>) {
	try {
		const response = yield* call(Api.Predictor.save, payload);

		ApiError.CHECK(response);
		if (response.result) {
			yield* put(postPredictorPredictionSuccess(response.result));
			yield* put(showCrossPromotionModal());
		}
	} catch (e) {
		const err = e as Error;
		yield* put(globalError(err));
	}
};

export const fetchGameBarSaga = function* ({payload}: ISagaAction<IQuestionPredictionPayload>) {
	try {
		const response = yield* call(Api.Predictor.game_bar, payload);
		ApiError.CHECK(response);

		if (response.result) {
			yield* put(fetchGameBarSuccess(response.result));
		}
	} catch (e) {
		const err = e as Error;
		yield* put(globalError(err));
	}
};

export const fetchLeaderBoardSaga = function* ({payload}: ISagaAction<ILeaderboardPayload>) {
	try {
		const LIMIT = 30;
		const offset: number = payload.offset || 0;
		const options: ILeaderboardRequest = {
			offset,
			limit: LIMIT,
			q: payload.q,
		};
		const leaderboard = yield* select(getLeaderBoard);

		const response = yield* call(Api.Predictor.leaderboard, options);
		ApiError.CHECK(response);

		const {result} = response;
		if (result) {
			const fresh_leaderboard = payload.is_load_more
				? [...leaderboard, ...result.leaderboard]
				: result.leaderboard;

			yield* put(
				fetchLeaderBoardSuccess({
					...result,
					leaderboard: fresh_leaderboard,
				})
			);

			if (result.next) {
				yield* take(getMoreLeaderboard);
				yield* put(
					fetchLeaderBoard({q: payload.q, offset: offset + LIMIT, is_load_more: true})
				);
			}
		}
	} catch (e) {
		const err = e as Error;
		yield* put(globalError(err));
	}
};
