import { isString, get } from 'lodash';
import { SagaIterator } from 'redux-saga';
import { put, call, select } from 'typed-redux-saga';
import {IError, ISagaAction} from 'modules/types';
import { ApiError, User } from 'modules/utils';
import { isLoggedIn } from "modules/selectors";
import * as actions from "modules/actions";

type TPayload = ApiError | Error | string;

// eslint-disable-next-line @typescript-eslint/require-await
const defaultErrorHandler = async (payload: TPayload) => {
	if (isString(payload)) {
		throw new Error(payload);
	}

	if (isString(payload.message)) {
		throw new Error(payload.message);
	}

	if (payload instanceof ApiError) {
		throw new Error(payload.text);
	}
};

const onError = {
	* [ApiError.AUTH_REQUIRED](error: TPayload): SagaIterator {
		if (yield* select(isLoggedIn)) {
			User.CLEAR();
			window.location.reload();
		}

		yield* call(defaultErrorHandler, error);
	},
};

export const errorsManagerSaga = function* ({payload}: ISagaAction<TPayload>): SagaIterator {
	const error_code = get(payload, 'code', '');

	try {
		const onErrorHandler = get(onError, error_code, defaultErrorHandler);
		yield* call(onErrorHandler, payload);
	} catch (err) {
		const error = err as IError;
		yield* put(actions.showGlobalError({
			text: error.message || '',
			code: error_code || 0,
		}));
	}
};