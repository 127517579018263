import {HTTPClient as HTTPClientCore, IRequestConfig} from "@fanhubmedia/fe-common-utils";
import {Storage} from "modules/utils/Storage";
import {Constant} from "modules/utils/Constant";
import {stringify} from "qs";
import axios, {AxiosError} from "axios";
import {CANCEL} from "redux-saga";
import {set, isBoolean, mapValues, each, isObject, identity, get} from "lodash";
import {
	IUserBackdoorLoginPayload,
	IUserLoginPayload,
	IPostContactForm,
	IUserUpdate,
	IUserReturnPayload,
	IChecksum,
	IFaqItem,
	IHelpPages,
	ICountry,
	IApiResponse,
	IUser,
	IEvent,
	IQuestionPredictionPayload,
	IQuestionPredictionResponse,
	IPredictionSavePayload,
	IGameBar,
	ILeaderboard,
	ILeaderboardRequest,
	IHistoricalStats,
	IRider,
} from "modules/types";
import ApiError from "modules/utils/Api/ApiError";

export const IS_API_ON_THE_SAME_HOST = Constant.API_URL.includes(window.location.host);

class HTTPClient extends HTTPClientCore {
	/**
	 * Overridden method adds CancelToken symbol, that allow redux-saga'
	 * "takeLatest" function to cancel any requests automatically.
	 */
	public makeRequest<T = unknown>(config: IRequestConfig): Promise<T> {
		const source = axios.CancelToken.source();

		const new_config = {
			...config,
			params: {
				_: Date.now(),
				...config.params,
			},
			cancelToken: source.token,
		};

		if (!IS_API_ON_THE_SAME_HOST) {
			set(new_config, "params.sid", Storage.GET("sid") || "");
		}

		const request = super.makeRequest(new_config);

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore: Legacy
		request[CANCEL] = () => source.cancel();

		return request;
	}
}

const onCatchNetworkError = ({response, message = "Network error"}: AxiosError) => {
	const data: unknown = response?.data;
	const error = isObject(data)
		? data
		: {
				errors: [message],
		  };

	return Promise.reject(error).catch((err) => ApiError.CHECK(err));
};

const ApiFormDataClient = new HTTPClient({
	baseURL: Constant.API_URL,
	withCredentials: true,
	transformRequest: [(data) => prepareFormData(data)],
});

// const ApiUserCreateClient = new HTTPClient({
// 	baseURL: Constant.API_URL,
// 	withCredentials: true,
// 	onCatchNetworkError,
// });

const APIClient = new HTTPClient({
	baseURL: Constant.API_URL,
	withCredentials: true,
	transformRequest: [stringify],
	onCatchNetworkError,
});

const JSONClient = new HTTPClient({
	baseURL: Constant.JSON_URL,
});

const prepareFormData = (data: Record<string, unknown>) => {
	const prep_data = mapValues(data, (value) => {
		if (isBoolean(value)) {
			return +value;
		}
		return value;
	});
	const formData = new FormData();
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore: TODO: rewrite
	each(prep_data, (value: string | Blob, key: string) => {
		if (value instanceof File) {
			formData.append(key, value);
			return;
		}
		formData.append(key, value);
	});
	formData.append("sid", Storage.GET("sid") || "");
	return formData;
};

type TUserResponse = IApiResponse<{user: IUser; session_id: string; is_registered: boolean}>;

type TPredictionResponse = IApiResponse<IQuestionPredictionResponse>;

type TGameBarResponse = IApiResponse<IGameBar>;
type TLeaderboardResponse = IApiResponse<ILeaderboard>;

const getPath = (key: string) => (data: unknown) => get(data, key);
const onApiResponse = getPath("data");

export const Api = {
	JSON: {
		checksums: () => JSONClient.get<IChecksum>("checksums.json"),
		events: () => JSONClient.get<IEvent[]>("events.json"),
		riders: () => JSONClient.get<IRider[]>("riders.json"),
		historical_stats: () => JSONClient.get<IHistoricalStats[]>("historical_stats.json"),
		// constructors: () => JSONClient.get<Record<string, unknown>>('constructors.json'),
		// squads: () => JSONClient.get<Record<string, unknown>>('squads.json'),
		faq: () => JSONClient.get<IFaqItem[]>("faq.json"),
		help_pages: () => JSONClient.get<IHelpPages>("help_pages.json"),
		countries: () => JSONClient.get<ICountry[]>("countries.json"),
		widget: () => JSONClient.get<Record<string, unknown>>("widget.json"),
	},
	Auth: {
		login: (params: IUserLoginPayload) => APIClient.post<TUserResponse>("auth/login", params),
		backdoor: (params: IUserBackdoorLoginPayload) =>
			APIClient.post<TUserResponse>("auth/backdoor", params),
		logout: () => APIClient.post("auth/logout"),
	},
	User: {
		create: (data: FormData) =>
			APIClient.request({
				data,
				method: "POST",
				url: "user/create",
				transformRequest: identity,
			})
				.then(onApiResponse)
				.catch(onCatchNetworkError),
		// create: (data: FormData) => ApiUserCreateClient.post<TUserResponse>("user/create", data),
		recover: (params: IUserReturnPayload) =>
			APIClient.post<TUserResponse>("user/recover", params),
		register_for_game: (params: IUserReturnPayload) =>
			APIClient.post<TUserResponse>("user/register_for_game", {...params, terms: 1}),
		update: (params: IUserUpdate) =>
			ApiFormDataClient.post<TUserResponse>("user/update", params),
		show_my: () => APIClient.get<TUserResponse>("user/show_my"),
		delete_friend: (params: {friend_id: number}) =>
			APIClient.post("user/delete_friend", params),
		add_friend: (params: {friend_id: number}) => APIClient.post("user/add_friend", params),
	},
	Contact: {
		save: (params: IPostContactForm) => APIClient.post("contact", params),
	},
	Predictor: {
		show_my: (params: IQuestionPredictionPayload) =>
			APIClient.get<TPredictionResponse>("predictor_prediction/show_my", params),
		save: (params: IPredictionSavePayload) =>
			APIClient.post<TPredictionResponse>("predictor_prediction/save", params),
		game_bar: (params: IQuestionPredictionPayload) =>
			APIClient.get<TGameBarResponse>("predictor_prediction/game_bar", params),
		leaderboard: (params: ILeaderboardRequest) =>
			APIClient.get<TLeaderboardResponse>("predictor_prediction/leaderboard", params),
	},
};

export * from "./ApiError";

export default Api;
