import {put, call} from "typed-redux-saga";
import * as actions from "modules/actions";
import {IError, IPostContactForm, ISagaAction} from "modules/types";
import {Api, ApiError} from "modules/utils";

export const postContactFormSaga = function* (action: ISagaAction<IPostContactForm>) {
	try {
		const response = yield* call(Api.Contact.save, action.payload);
		ApiError.CHECK(response);

		yield* put(actions.postContactFormSuccess());
	} catch (e) {
		const error = e as IError;
		yield* put(actions.postContactFormFailed(error));
	}
};
